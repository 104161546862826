<template>
    <div class="pieChart"></div>
</template>

<script>
export default {
    name: '',
    data() {
        return {}
    },
    methods: {
        setChart() {

            //智联招聘
            let zl=20;
            //51job
            let job=13;
            //BOSS直聘
            let boss=45;
            //内部推荐
            let inner=17;
            //其他
            let other=5;
            this.$http
                .get('https://hr.estar-info.com/hrApiController.do?channelCatagory')
                .then(
                    response => {//successcallback
                        zl = response.body.obj.zl;
                        job = response.body.obj.job;
                        boss = response.body.obj.boss;
                        inner = response.body.obj.inner;
                        other = response.body.obj.other;

                        let option = {
                            tooltip: {
                                trigger: 'item',
                                formatter: "{a} <br/>{b} : {c} ({d}%)"
                            },
                            legend: {
                                data: [

                                ],
                                left: "8%",
                                top: "10%",
                                itemWidth: 7,
                                itemHeight: 7,
                                textStyle: {
                                    color: '#00CCFF',
                                    fontSize: 10
                                }
                            },
                            series: [
                                {
                                    name: '渠道统计',
                                    type: 'pie',
                                    radius: '40%',
                                    center: ['13%', '45%'],
                                    data: [
                                        {value: zl, name: '智联招聘'},
                                        {value: job, name: '51job'},
                                        {value: other, name: '其他'},
                                        {value: inner, name: '内部推荐'},
                                        {value: boss, name: 'BOSS直聘'}
                                    ],
                                    label: {
                                        fontSize: 8,
                                        color: '#00CCFF'
                                    },
                                    labelLine: {
                                        length: 15,
                                        length2: 10,
                                        lineStyle: {
                                            color: '#3F3F5C'
                                        }
                                    },
                                    itemStyle: {
                                        color: function (params) {
                                            var colorList = ['#c2232a', '#fe672c', '#61B9C8', '#00CCFF', '#a262f2'];
                                            return colorList[params.dataIndex];
                                        },
                                        emphasis: {
                                            shadowBlur: 10,
                                            shadowOffsetX: 0,
                                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                                        }
                                    }
                                },
                            ]
                        };
                        let myChart = this.$echarts(this.$el);
                        myChart.clear();
                        myChart.resize()
                        myChart.setOption(option);

                    },
                    response => {//errorcallback
                        alert("失败！");
                        console.log(JSON.stringify(response))//错误信息
                    })

        }
    },
    mounted() {
        this.setChart()
    },
}
</script>

<style lang="less" scoped>
.pieChart {
    height: 120%;
    width: 185%;
    padding: 0 10px;
}
</style>
